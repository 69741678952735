<template>
  <div class="footer">
    <div class="container text-center">
      <h3>{{ config.blogTitle }}</h3>
      <p><i>{{ config.description }}</i></p>
      <br />
      <p v-if="curYear === config.blogStartYear">
        &copy; {{ curYear }} {{ config.name }}.&nbsp;
        Powered by XiaoHang.
      </p>
      <p v-else>
        &copy; {{ config.blogStartYear }} - {{ curYear }} {{ config.name }}.&nbsp;
        Powered by XiaoHang.
      </p>
      <p>
        <a href="http://beian.miit.gov.cn/" target="_blank">{{ beian }}</a>
      </p>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data: function () {
    return {
      config: this.getConfig().config,
      curYear: new Date().getFullYear(),
      beian: '粤ICP备17107118号-2',
    }
  },
  mounted() {
    this.setInfo();
  },
  methods: {
    getYear: function () {
      return new Date().getFullYear()
    },
    setInfo() {
      console.log('setInfo')
      let href = location.href;
      if (href.indexOf('mapscrip.cn') != -1) {
        document.title = 'MapScrip地图纸条';
        this.beian = '粤ICP备17107118号-1'
      } else if (href.indexOf('imbear.top') != -1) {
        document.title = '个人生活记录';
        this.beian = '粤ICP备17107118号-2'
      }
    }
  }
}
</script>
